import { Values } from './types'
import { getIsDateBlocked } from './utils'
import CountrySelect from '@components/CountrySelect'
import DatepickerInput from '@components/Datepicker'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Field } from '@components/gassan-ui'
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import { Box } from '@gassan-ui'
import { FormField, useMeQuery } from '@generated'
import { useFormikContext } from 'formik'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type FieldsProps = {
  formId: string
  fields: FormField[]
  selectableDates: string[]
  selectableTimes: string[]
  maxPersons?: number
  newsletterText?: String
}

export const Fields: FC<FieldsProps> = ({
  formId,
  fields,
  selectableDates,
  selectableTimes,
  maxPersons = 50,
  newsletterText,
}) => {
  const { t } = useTranslation('forms')
  const { setFieldValue, values, setTouched } = useFormikContext<Values>()
  const [meQuery] = useMeQuery()

  return (
    <>
      <Field
        name="firstName"
        label={t('labels.firstName')}
        field="input"
        gridColumn="span 12"
        autoComplete="given-name"
      />
      <Field
        name="lastName"
        label={t('labels.lastName')}
        field="input"
        gridColumn="span 12"
        autoComplete="family-name"
      />
      <Field
        name="email"
        label={t('labels.email')}
        field="input"
        gridColumn="span 12"
        autoComplete="email"
        disabled={!!meQuery.data?.me?.email}
      />
      {fields.includes(FormField.HomeAddress) && (
        <>
          <Field name="street" label={t('labels.street')} field="input" gridColumn="span 5" />
          <Field
            field="input"
            name="houseNumber"
            label={t('labels.houseNumber')}
            gridColumn={{ _: 'span 7', large: 'span 4' }}
          />
          <Field
            field="input"
            name="houseNumberSuffix"
            label={t('labels.houseNumberSuffix')}
            gridColumn={{ _: 'span 5', large: 'span 3' }}
          />
          <Field
            field="input"
            name="postalCode"
            label={t('labels.postalCode')}
            gridColumn={{ _: 'span 12', large: 'span 5' }}
            autoComplete="postal-code"
          />
          <Field
            name="city"
            label={t('labels.city')}
            field="input"
            gridColumn={{ _: 'span 12', large: 'span 7' }}
            autoComplete="address-level2"
          />
          <CountrySelect name="country" label={t('labels.country')} />
        </>
      )}
      {fields.includes(FormField.PhoneNumber) && (
        <Field
          name="phoneNumber"
          label={t('labels.phone')}
          field="input"
          gridColumn="span 12"
          inputMode="tel"
          autoComplete="tel"
        />
      )}
      {fields.includes(FormField.Message) && (
        <Field
          name="message"
          label={t('labels.messageAppointment')}
          field="textarea"
          gridColumn="span 12"
        />
      )}
      {fields.includes(FormField.DietaryRequirements) && (
        <Field
          name="dietaryRequirements"
          label={t('labels.dietaryRequirements')}
          field="textarea"
          gridColumn="span 12"
        />
      )}
      {fields.includes(FormField.NumberOfPersons) && typeof maxPersons === 'number' && (
        <Field
          name="numberOfPersons"
          label={t('labels.numberOfPersons')}
          field="select"
          placeholder=" "
          gridColumn="span 12"
        >
          <option value="" defaultChecked disabled>
            {t('labels.numberOfPersons')}
          </option>
          {[...Array.from(new Array(maxPersons))].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </Field>
      )}

      {fields.includes(FormField.Date) && (
        <DatepickerInput
          isDateBlocked={getIsDateBlocked(selectableDates)}
          name="preferredDate"
          label={t('date', { ns: 'other' })}
          value={values.date}
          onChange={(date) => {
            setFieldValue('date', date)
          }}
          onBlur={() => setTouched({ date: true })}
        />
      )}
      {fields.includes(FormField.Time) && selectableTimes.length > 0 && (
        <Field
          name="time"
          label={t('labels.numberOfPersons', { ns: 'forms' })}
          field="select"
          placeholder=" "
          gridColumn="span 12"
        >
          <option value="" defaultChecked disabled>
            {t('labels.selectTime', { ns: 'forms' })}
          </option>
          {selectableTimes.map((time, i) => (
            <option key={i} value={time}>
              {time}
            </option>
          ))}
        </Field>
      )}
      {fields.includes(FormField.HeardOfGassanVia) && (
        <Field
          name="heardOfGassanVia"
          label={t('labels.heardOfGassanVia', { ns: 'forms' })}
          field="input"
          gridColumn="span 12"
        />
      )}
      <Field
        id={`${formId}-newsletter-signup-checkbox`}
        name="newsletterSignup"
        label={
          <>
            {newsletterText || t('labels.acceptNewsletterAndConditions', { ns: 'forms' })}{' '}
            <LocalizedLink href={t('privacyPolicy', { ns: 'navigation' })}>
              <a href={t('privacyPolicy', { ns: 'navigation' })}>
                {t('moreInfo', { ns: 'other' })}
              </a>
            </LocalizedLink>
          </>
        }
        field="checkbox"
        gridColumn="span 12"
      />
      {fields.includes(FormField.GassanPlazaNews) && (
        <Field
          id={`${formId}-plaza-brillant-news`}
          name="plazaBrilliantNews"
          label={t('labels.subscribePlazaBrilliantNews', { ns: 'forms' })}
          field="checkbox"
          gridColumn="span 12"
        />
      )}
      {fields.includes(FormField.CreateAccount) && (
        <Box gridColumn="span 12" mt="1rem">
          <LinariaText>
            {t('wantToCreateAccount', { ns: 'other' })}
            <LocalizedLink href={t('account.create.url', { ns: 'navigation' })} passHref>
              <a>{t('here', { ns: 'other' })}</a>
            </LocalizedLink>
            .
          </LinariaText>
        </Box>
      )}
    </>
  )
}
