import { FormField } from '@generated'
import {
  cityRequired,
  countryRequired,
  emailRequired,
  firstNameRequired,
  houseNumberRequired,
  internationalPostalCodeRequired,
  lastNameRequired,
  phoneRequired,
  postalCodeRequired,
  streetRequired,
} from '@lib/yup'
import { isSameDay } from 'date-fns'
import * as Yup from 'yup'

export const getSchema = (inputFields: FormField[]) => {
  let schema = Yup.object().shape({
    firstName: firstNameRequired,
    lastName: lastNameRequired,
    email: emailRequired,
    newsletterSignup: Yup.boolean().oneOf([true], 'forms.errors.privacy'),
  })

  if (inputFields.includes(FormField.HomeAddress)) {
    schema = schema.concat(
      Yup.object().shape({
        postalCode: Yup.string().when('country', {
          is: 'NL',
          then: () => postalCodeRequired,
          otherwise: () => internationalPostalCodeRequired,
        }),
        country: countryRequired,
        city: cityRequired,
        street: streetRequired,
        houseNumber: houseNumberRequired,
        houseNumberSuffix: Yup.string(),
      }),
    )
  }

  if (inputFields.includes(FormField.PhoneNumber)) {
    schema = schema.concat(
      Yup.object().shape({
        phoneNumber: phoneRequired,
      }),
    )
  }

  if (inputFields.includes(FormField.Message)) {
    schema = schema.concat(
      Yup.object().shape({
        message: Yup.string(),
      }),
    )
  }

  if (inputFields.includes(FormField.GassanPlazaNews)) {
    schema = schema.concat(
      Yup.object().shape({
        plazaBrilliantNews: Yup.boolean(),
      }),
    )
  }

  if (inputFields.includes(FormField.DietaryRequirements)) {
    schema = schema.concat(
      Yup.object().shape({
        dietaryRequirements: Yup.string(),
      }),
    )
  }

  if (inputFields.includes(FormField.NumberOfPersons)) {
    schema = schema.concat(
      Yup.object().shape({
        numberOfPersons: Yup.string(),
      }),
    )
  }

  if (inputFields.includes(FormField.HeardOfGassanVia)) {
    schema = schema.concat(
      Yup.object().shape({
        heardOfGassanVia: Yup.string(),
      }),
    )
  }

  if (inputFields.includes(FormField.Date)) {
    schema = schema.concat(
      Yup.object().shape({
        date: Yup.string(),
      }),
    )
  }

  if (inputFields.includes(FormField.Time)) {
    schema = schema.concat(
      Yup.object().shape({
        time: Yup.string(),
      }),
    )
  }

  return schema
}

export const getIsDateBlocked = (allowedDates: string[]) => {
  return (date: Date) => {
    for (const allowedDate of allowedDates) {
      if (isSameDay(date, new Date(allowedDate))) {
        return false
      }
    }
    return true
  }
}
