// @ts-strict-ignore
import { Box, Button, Container, Heading, Image } from '@components/gassan-ui'
import { getKontentImage } from '@lib/kontent'
import { CbCenteredTextOnImageBackground } from '@lib/kontent/models/CbCenteredTextOnImageBackground'
import React, { FC } from 'react'

type Props = CbCenteredTextOnImageBackground

const CenteredTextOnWideImageBackground: FC<Props> = ({
  title,
  description,
  desktop_image,
  mobile_image,
  button_text,
  button_url,
  large_text_variant,
  text_color,
  title_tag,
}) => {
  const variant = large_text_variant.value[0] ? 'large' : 'regular'
  const textColor = text_color.value[0].codename === 'light' ? 'light' : 'dark'
  const headingType = title_tag.value[0] ? title_tag.value[0].codename : 'h2'
  return (
    <Box
      display="flex"
      alignItems={{ _: 'flex-end', large: 'center' }}
      position="relative"
      pt={{ _: '3.5rem', small: '9rem', large: '6rem', huge: '11.5rem' }}
      pb={{ _: '3.5rem', small: '9rem', large: '6rem', huge: '11.5rem' }}
    >
      <Box zIndex={-1} position="absolute" height="100%" top="0" width="100%">
        <Image
          className="h-full w-full"
          sources={[
            getKontentImage(mobile_image.value[0].url, { width: 600 }),
            getKontentImage(mobile_image.value[0].url, { width: 500 }),
            getKontentImage(desktop_image.value[0].url, { width: 1400 }),
            getKontentImage(desktop_image.value[0].url, { width: 2000 }),
          ]}
          alt={desktop_image.value[0].description}
          placeholder={getKontentImage(mobile_image.value[0].url, { width: 50 })}
        />
      </Box>
      <Box zIndex={2} position="relative" width="100%">
        <Container maxWidth={textColor === 'light' ? '50rem' : '53rem'}>
          <Box textAlign="center" mx="auto">
            <Heading
              variant={variant === 'large' ? 'hXl' : 'h1'}
              as={headingType as React.ElementType}
              mb={{ _: '1rem', large: '1.5rem' }}
              color={textColor === 'light' ? 'white' : 'initial'}
            >
              {title.value}
            </Heading>
            <Heading
              variant="h3"
              color={textColor === 'light' ? 'white' : 'initial'}
              mb="0"
              style={{ whiteSpace: 'pre-line' }}
            >
              {description.value}
            </Heading>
            {button_text.value && button_url.value && (
              <Box mt={{ _: '2rem', small: '2.5rem', large: '2.5rem' }} display="inline-block">
                <Button variant="light" as="link" href={button_url.value}>
                  {button_text.value}
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default CenteredTextOnWideImageBackground
