// @ts-strict-ignore
import { OptinOptoutFormFields } from './Fields'
import * as styles from './styles'
import { useNotifications } from '@components/NotificationsProvider'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { linariaTheme } from '@config/theme'
import { Button, Field, Heading, Icon, Select } from '@gassan-ui'
import { useUpdateNewsletterOptinMutation } from '@generated'
import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'

type OptinOptoutStatus = 'idle' | 'loading' | 'success'
type OptinOptoutFormProps = {
  email: string | null
}

export const OptinOptoutForm: FC<OptinOptoutFormProps> = ({ email }) => {
  const timer = useRef<NodeJS.Timeout>()
  const [status, setStatus] = useState<OptinOptoutStatus>('idle')
  const { t } = useTranslation(['account', 'notifications', 'other'])
  const notifications = useNotifications()
  const [, updateNewsletterOptin] = useUpdateNewsletterOptinMutation()

  useEffect(() => {
    if (status === 'success') {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setStatus('idle')
      }, 5000)
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [status])

  let schema = Yup.object().shape({
    optin100: Yup.boolean(),
    optin101: Yup.boolean(),
    optin102: Yup.boolean(),
    reason: Yup.string().required(),
    customReason: Yup.string().when('reason', {
      is: 'other',
      then: (schema) => schema.required(),
    }),
  })

  const startValues = {
    optin100: false,
    optin101: false,
    optin102: false,
    reason: '',
    customReason: '',
  }

  if (!email) {
    return null
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={startValues}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { resetForm }) => {
        const { optin100, optin101, optin102, reason, customReason } = values
        const applicableReason = reason === 'other' ? customReason : reason

        const { data, error } = await updateNewsletterOptin({
          input: {
            email,
            optin100: !optin100,
            optin101: !optin101,
            optin102: !optin102,
            reason: applicableReason,
          },
        })
        if (error) {
          if (process.env.NODE_ENV === 'development') {
            console.error(error)
          }
          notifications.addNotification({
            variant: 'error',
            label: t('refreshAndTryAgain', { ns: 'notifications' }),
          })
        }

        if (data && data.updateNewsletterOptin) {
          setStatus('success')
          notifications.addNotification({
            variant: 'simple-confirmation',
            label: t('preferences.saved', { ns: 'account' }),
          })
          resetForm()
        }
      }}
    >
      {({ isSubmitting, setFieldValue, values, errors }) => (
        <Form>
          <Heading variant="h4" as="h2">
            {t('optOutTitle', { ns: 'other' })}
          </Heading>
          <div>
            <OptinOptoutFormFields />
            <div className={styles.reasonBlock}>
              {status === 'success' ? (
                <div className={styles.success}>
                  <Icon icon="account-check" color={linariaTheme.colors.tabaccoBrown} />
                  <LinariaHeading variant="h5" as="h3" mb="0">
                    {t('thanksForFeedback', { ns: 'other' })}{' '}
                    <a
                      target="_blank"
                      href="https://www.facebook.com/gassandiamonds"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                    ,{' '}
                    <a target="_blank" href="https://instagram.com/gassandiamonds" rel="noreferrer">
                      Instagram
                    </a>
                  </LinariaHeading>
                </div>
              ) : (
                <>
                  <LinariaHeading variant="h5" as="h3" mb="4">
                    {t('reasonUnsubscribing', { ns: 'other' })}
                  </LinariaHeading>
                  <Select
                    status={errors.reason ? 'error' : 'valid'}
                    value={values.reason}
                    onChange={(e) => setFieldValue('reason', e.target.value)}
                  >
                    <option value=""></option>
                    {t<string, any[]>('unsubscribeReasons', {
                      ns: 'other',
                      returnObjects: true,
                    }).map((reason, key) => {
                      const isLast =
                        key ===
                        t<string, any[]>('unsubscribeReasons', { ns: 'other', returnObjects: true })
                          .length -
                          1
                      return (
                        <option value={isLast ? 'other' : reason} key={key}>
                          {reason}
                        </option>
                      )
                    })}
                  </Select>
                </>
              )}

              {values.reason === 'other' && (
                <Field
                  mt="2"
                  mb="4"
                  status={errors.customReason ? 'error' : 'valid'}
                  rows={2}
                  field="textarea"
                  name="customReason"
                  label={t('enterReason', { ns: 'other' })}
                ></Field>
              )}
            </div>
            <Button variant="dark" type="submit" status={isSubmitting ? 'loading' : 'idle'}>
              {t('submitUnsubscribe', { ns: 'other' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
