import { BrandsListBrandsRow } from '@components/_brands/BrandsList/BrandsRow'
import { Container } from '@components/gassan-ui'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { BrandPage } from '@lib/kontent/models/BrandPage'
import { CbBrands } from '@lib/kontent/models/CbBrands'
import { FC } from 'react'

type BrandsProps = CbBrands & {
  items: BrandPage[]
}

const Brands: FC<BrandsProps> = ({ title, items }) => {
  const normalized = items.map((item) => ({
    title: item.title,
    url_slug: item.url_slug,
    logo: item.logo,
    brandId: item.daf_filters__brand_nr.value
      ? parseInt(item.daf_filters__brand_nr.value, 0)
      : null,
  }))

  return (
    <Container mb={{ _: '2rem', small: '4rem', large: '5rem' }}>
      <LinariaHeading variant="h2" as="h2" mb="0">
        {title.value}
      </LinariaHeading>
      <BrandsListBrandsRow isOpen={true} items={normalized} />
    </Container>
  )
}

export default Brands
