// @ts-strict-ignore
import { Image } from '@gassan-ui'
import { getKontentImage } from '@lib/kontent'
import * as Elements from '@lib/kontent/models/Elements'
import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'

type Props = {
  image: Elements.AssetsElement['value'][0]
}

const ActiveSlide: FC<Props> = ({ image }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ easing: 'easeOut', duration: 0.2 }}
        key={image.url}
      >
        <Image
          className="aspect-[20/13]"
          alt={image.description}
          sources={[
            getKontentImage(image.url, { width: 650 }),
            getKontentImage(image.url, { width: 900 }),
          ]}
          placeholder={getKontentImage(image.url, { width: 30 })}
        />
      </motion.div>
    </AnimatePresence>
  )
}

export default ActiveSlide
