import { Box, SliderNavButton, Text, breakpoints, theme } from '@gassan-ui'
import * as Elements from '@lib/kontent/models/Elements'
import { rem, rgba } from 'polished'
import { FC } from 'react'
import styled from 'styled-components'

const NavButton = styled(SliderNavButton)`
  background: blue;
  opacity: 1 !important;
  border-radius: 100%;
  height: 2rem;
  width: 2rem;
  background: ${theme.colors.pampasLight};
  color: ${(p) => (p.disabled ? theme.colors.tabaccoLight : theme.colors.shades[800])};

  @media screen and (min-width: ${breakpoints.small}) {
    width: 3.5rem;
    height: 3.5rem;
  }
`

type Props = {
  images: Elements.AssetsElement['value']
  index: number
  onPrev: () => void
  onNext: () => void
}

const SlideNav: FC<Props> = ({ images, index, onPrev, onNext }) => {
  return (
    <>
      <Box
        position="absolute"
        top="50%"
        left={{ _: '0.5rem', small: '1rem', large: '-1.75rem' }}
        width={{
          _: 'calc(100% - 1rem)',
          small: 'calc(100% - 2rem)',
          large: 'calc(100% + 3.5rem)',
        }}
        style={{ transform: 'translateY(-50%)' }}
        display="flex"
        justifyContent="space-between"
        zIndex={2}
      >
        <NavButton onClick={onPrev} direction="prev" disabled={index === 0} />
        <NavButton onClick={onNext} direction="next" disabled={index === images.length - 1} />
      </Box>
      <Box
        position="absolute"
        bottom={{ _: '1rem', small: '1.5rem' }}
        left={{ _: '0.5rem', small: '1.5rem' }}
        borderRadius={rem(22)}
        bg={rgba('#ffffff', 0.7)}
        display="flex"
        alignItems="center"
        textAlign="center"
        zIndex={2}
        p="0.25rem 0.5rem"
      >
        <Text as="span" variant="micro" mb="0">{`${index + 1}/${images.length}`}</Text>
      </Box>
    </>
  )
}

export default SlideNav
