import LocalizedLink from '@components/Localization/LocalizedLink'
import { Box, Container, Flex, Heading, Link, Text, VideoPlayer } from '@gassan-ui'
import { getKontentImage } from '@lib/kontent'
import { HomepageCbCraftsmanshipVideo } from '@lib/kontent/models/HomepageCbCraftsmanshipVideo'
import { FC } from 'react'

type Props = HomepageCbCraftsmanshipVideo

const CbHomepageCraftsmanshipVideo: FC<Props> = ({
  text___video___content_block__title,
  text___video___content_block__description,
  text___video___content_block__link_title,
  text___video___content_block__link_url,
  text___video___content_block__desktop_placeholder_image,
  text___video___content_block__video_title,
  text___video___content_block__video,
}) => {
  const videoSrc = text___video___content_block__video.value[0]?.url
  const placeholderSrc = getKontentImage(
    text___video___content_block__desktop_placeholder_image.value[0]?.url,
    { width: 50 },
  )
  const videoImgSrcs = [
    getKontentImage(text___video___content_block__desktop_placeholder_image.value[0]?.url, {
      width: 450,
    }),
    getKontentImage(text___video___content_block__desktop_placeholder_image.value[0]?.url, {
      width: 750,
    }),
  ]

  return (
    <Box mt={['3.5rem', '3.5rem', '6rem']} mb={['1.25rem', '2.5rem', '2.5rem', '5rem']}>
      <Container
        display="grid"
        gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
        gridTemplateAreas={['initial', 'initial', 'initial', '"left right"']}
        gridGap="3"
        maxWidth="90rem"
      >
        <Flex alignItems="center" justifyContent="center">
          <VideoPlayer
            videoSrc={videoSrc}
            placeholderSrc={placeholderSrc}
            images={videoImgSrcs}
            ratio={[0.5, 0.5, 0.7]}
            playMode="regular"
            text={text___video___content_block__video_title.value}
          />
        </Flex>
        <Flex
          textAlign={['center', 'center', 'center', 'left']}
          gridArea={['initial', 'initial', 'initial', 'left']}
          alignItems={['center', 'center', 'center', 'flex-start']}
          justifyContent="center"
          flexDirection="column"
          maxWidth={['100%', '90%', '30rem', '30rem', '31rem']}
          margin={['0 auto', '0 auto', '0 auto', 'initial']}
          ml={['inherit', 'inherit', 'inherit', '3rem', '3rem']}
          py={['1rem', '1rem', '2rem', '5.5rem']}
        >
          <Heading variant="h2" as="h2">
            {text___video___content_block__title.value}
          </Heading>
          <Text mb={['2rem', '2rem', '2rem', '2rem', '2.5rem']}>
            {text___video___content_block__description.value}
          </Text>
          <LocalizedLink href={text___video___content_block__link_url.value}>
            <Link href={text___video___content_block__link_url.value}>
              {text___video___content_block__link_title.value}
            </Link>
          </LocalizedLink>
        </Flex>
      </Container>
    </Box>
  )
}

export default CbHomepageCraftsmanshipVideo
