// @ts-strict-ignore
import { Button, Image } from '@gassan-ui'
import { cn } from '@lib/cn'
import { getKontentImage } from '@lib/kontent'
import { HomepageCbCtaBanner } from '@lib/kontent/models/HomepageCbCtaBanner'
import { FC } from 'react'

type Props = HomepageCbCtaBanner

const HomepageCtaBanner: FC<Props> = ({
  cta_banner__button_text,
  cta_banner__button_url,
  cta_banner__mobile_image,
  cta_banner__desktop_image,
}) => {
  const sources = [
    getKontentImage(cta_banner__mobile_image.value[0].url, { width: 640 }),
    getKontentImage(cta_banner__mobile_image.value[0].url, { width: 768 }),
    getKontentImage(cta_banner__desktop_image.value[0].url, { width: 1024 }),
    getKontentImage(cta_banner__desktop_image.value[0].url, { width: 1344 }),
    getKontentImage(cta_banner__desktop_image.value[0].url, { width: 1444 }),
    getKontentImage(cta_banner__desktop_image.value[0].url, { width: 1635 }),
  ]

  return (
    <div
      className={cn(
        'relative mx-auto mb-14 w-full max-w-[109rem]',
        'md:w-[calc(100%-3rem)]',
        'xl:mb-20 xl:w-[calc(100%-5.5rem)]',
      )}
    >
      <div
        className={cn(
          '-z-[1]',
          'before:block before:w-full before:pt-[70%] before:content-[""]',
          'md:landscape:before:overflow-hidden md:landscape:before:pt-[40%]',
          'lg:landscape:before:pt-[25%]',
        )}
      >
        <Image
          sources={sources}
          className="absolute left-0 top-0 h-full w-full"
          placeholderSrc={getKontentImage(cta_banner__mobile_image.value[0].url, { width: 50 })}
          alt={cta_banner__mobile_image.value[0].description}
        />
      </div>
      {cta_banner__button_url.value && cta_banner__button_text.value && (
        <div
          className={cn(
            'absolute bottom-4 left-0 right-0 z-[2] mx-auto flex w-[calc(100%-3rem)] justify-center',
            'sm:bottom-[3.25rem]',
            'lg:bottom-8',
            'xl:bottom-[3.75rem]',
          )}
        >
          <Button
            className={cn('min-w-full sm:min-w-[inherit]')}
            as="link"
            href={cta_banner__button_url.value}
          >
            {cta_banner__button_text.value}
          </Button>
        </div>
      )}
    </div>
  )
}

export default HomepageCtaBanner
