// @ts-strict-ignore
import { Box, Flex, Heading, Image, Link, Text } from '@components/gassan-ui'
import { getAspectRatioImage } from '@lib/get-image-aspect-ratio'
import { getKontentImage } from '@lib/kontent'
import { AssetsElement } from '@lib/kontent/models/Elements'
import { FC } from 'react'

type Props = {
  title: string
  description: string
  image?: AssetsElement
  linkText: string
  linkHref: string
  variant: 'double' | 'triple'
}

const Item: FC<Props> = ({ title, description, image, linkText, linkHref, variant }) => {
  return (
    <Box
      mb={variant === 'double' && { _: '2rem', small: '4rem', large: '0' }}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box mb={{ _: '1rem', small: '1.5rem', large: '2rem' }}>
        <Image
          style={{ aspectRatio: `${getAspectRatioImage(image)}` }}
          alt={image.value[0].description}
          sources={[
            getKontentImage(image.value[0].url, { width: 500 }),
            getKontentImage(image.value[0].url, { width: 700 }),
          ]}
          placeholder={getKontentImage(image.value[0].url, { width: 50 })}
        />
      </Box>
      <Flex
        textAlign={{ _: 'left', large: 'center' }}
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        alignItems={{ _: 'flex-start', large: 'center' }}
        px={variant === 'double' && { _: '1rem', small: '2rem', large: 'initial' }}
      >
        <div>
          <Heading variant="h3" mb="0.5rem">
            {title}
          </Heading>
          <Text maxWidth="26rem">{description}</Text>
        </div>
        {linkText && linkHref && (
          <Box mt={{ _: '1.5rem', large: '2rem' }} display="inline-block">
            <Link variant="brown" href={linkHref}>
              {linkText}
            </Link>
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export default Item
