// @ts-strict-ignore
import { Box, Container, Image, SliderNavButton, Track, useSlider } from '@gassan-ui'
import { getKontentImage } from '@lib/kontent'
import { CbCarouselSquareWide } from '@lib/kontent/models/CbCarouselSquareWide'
import { CbSingleImageCarouselSquareWide } from '@lib/kontent/models/CbSingleImageCarouselSquareWide'
import { rem } from 'polished'
import { FC, useRef } from 'react'

type Props = CbCarouselSquareWide & {
  images: CbSingleImageCarouselSquareWide[]
}

const CarouselSquareWide: FC<Props> = ({ images_drag_drop, images }) => {
  const normalizedImages = getNormalizedImages(images)
  const items = images_drag_drop.value ? images_drag_drop.value : normalizedImages
  const trackRef = useRef<HTMLDivElement>(null)
  const { onTouchEnd, prev, next, canMoveRight, canMoveLeft } = useSlider({ trackRef })

  return (
    <Box mb={{ _: '2rem', small: '4rem', large: '5rem' }}>
      <Container
        position="relative"
        display="flex"
        justifyContent="flex-end"
        mb={{ _: '0.5rem', medium: '1.5rem' }}
      >
        <SliderNavButton onClick={prev} direction="prev" disabled={!canMoveLeft} />
        <SliderNavButton onClick={next} direction="next" disabled={!canMoveRight} />
      </Container>
      <Track ref={trackRef} onTouchEnd={onTouchEnd}>
        {items.map((image, key) => (
          <Box
            display="flex"
            key={key}
            minWidth={{ _: '55vw', medium: '21vw' }}
            style={{ scrollSnapAlign: 'start' }}
          >
            <Image
              className="aspect-square"
              alt={image.description}
              sources={[
                getKontentImage(image.url, { width: 300 }),
                getKontentImage(image.url, { width: 500 }),
              ]}
              placeholder={getKontentImage(image.url, { width: 30 })}
            />
            <Box minWidth={{ _: rem(5), medium: '0.5rem' }} height="100%" />
          </Box>
        ))}
      </Track>
    </Box>
  )

  function getNormalizedImages(images: CbSingleImageCarouselSquareWide[]) {
    return images.map((image) => {
      return {
        ...image.image.value[0],
      }
    })
  }
}

export default CarouselSquareWide
