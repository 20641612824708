import Double from './Double'
import Triple from './Triple'
import { CbSingleTextImage } from '@lib/kontent/models/CbSingleTextImage'
import { FC } from 'react'

type Props = {
  items: CbSingleTextImage[]
}

const MultipleTextImageBoxed: FC<Props> = ({ items }) => {
  if (![2, 3].includes(items.length)) {
    return null
  }

  return <>{items.length === 3 ? <Triple items={items} /> : <Double items={items} />}</>
}

export default MultipleTextImageBoxed
