import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import { Icon, Icons } from '@gassan-ui'
import { cn } from '@lib/cn'
import { FC } from 'react'

type UspProps = {
  label: string
  icon: keyof Icons
}

const Usp: FC<UspProps> = ({ label, icon }) => (
  <li
    className={cn(
      '-mb-4 grid grid-cols-[1rem_1fr] items-center gap-3 text-left',
      '[&_svg]:-ml-3',
      'md:mb-0 md:gap-4',
    )}
  >
    <Icon size={40} icon={icon} />
    <LinariaText mb="0" variant="small">
      {label}
    </LinariaText>
  </li>
)

export default Usp
