export function createLogger(context: string, enabled = true) {
  if (!enabled) {
    return {
      info: (message: any, ...args: any[]) => undefined,
      error: (message: any, ...args: any[]) => undefined,
      warn: (message: any, ...args: any[]) => undefined,
    }
  }

  return {
    info: (message: any, ...args: any[]) => console.info(context, message, args.length && args),
    error: (message: any, ...args: any[]) => console.error(context, message, args.length && args),
    warn: (message: any, ...args: any[]) => console.warn(context, message, args.length && args),
  }
}
