import { parse } from '@config/theme';
export const reasonBlock = parse({
  mt: '6',
  mb: '6',
  p: {
    _: '4',
    large: '8'
  },
  bg: 'pampasLight',
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%'
}, "r19d0xvy");
export const success = parse({
  display: 'flex',
  alignItems: 'center'
}, "s99n9jv");

require("./styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");