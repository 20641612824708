import { emailRequired, lastNameRequired, messageRequired } from '@lib/yup'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: lastNameRequired,
  email: emailRequired,
  subject: Yup.string(),
  message: messageRequired,
})
