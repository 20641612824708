// @ts-strict-ignore
import { LinariaLink } from '@components/gassan-ui/LinariaLink'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import { Button, Container, Image, breakpoints } from '@gassan-ui'
import { cn } from '@lib/cn'
import { getKontentImage } from '@lib/kontent'
import { HomepageCbAboutDiamonds } from '@lib/kontent/models/HomepageCbAboutDiamonds'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import styled from 'styled-components'

const StyledImage = styled(Image)`
  @media screen and (max-width: ${breakpoints.medium}) and (orientation: landscape) {
    overflow: hidden;
    max-height: 70vh;
  }
`

const HomepageAboutDiamonds: FC<HomepageCbAboutDiamonds> = ({
  pretitle,
  title,
  description,
  desktop_image,
  mobile_image,
  shop_button_url,
  more_info_button_url,
}) => {
  const { t } = useTranslation('global')

  const sources = [
    getKontentImage(mobile_image.value[0].url, { width: 375 }),
    getKontentImage(mobile_image.value[0].url, { width: 750 }),
    getKontentImage(desktop_image.value[0].url, { width: 1440 }),
    getKontentImage(desktop_image.value[0].url, { width: 1920 }),
    getKontentImage(desktop_image.value[0].url, { width: 2560 }),
  ]

  return (
    <section
      className={cn(
        'relative mx-auto mb-5 mt-14 max-w-[160rem] bg-white',
        'sm:mb-0 sm:bg-shade-50',
        'md:mt-[6.5rem]',
        'lg:mb-20 lg:bg-white',
      )}
    >
      <div className={cn('-z-[1] -mb-10', 'sm:-mb-24')}>
        <StyledImage
          className={cn('aspect-[8/5]', 'md:aspect-[5/3]', 'lg:aspect-[20/8]', 'huge:aspect-[4/1]')}
          sources={sources}
          placeholderSrc={getKontentImage(mobile_image.value[0].url, { width: 50 })}
          alt={mobile_image.value[0].description}
        />
      </div>
      <Container textAlign={['center', 'center', 'center', 'left']} maxWidth="90rem!important">
        <div
          className={cn(
            'relative z-[2] mx-auto max-w-[34rem] bg-pampas p-8',
            'sm:p-12',
            'lg:absolute lg:-bottom-12 lg:ml-0 lg:max-w-[42rem]',
            'xl:-ml-4 xl:p-16',
            'xxl:-ml-8 xxl:p-20',
          )}
        >
          <LinariaHeading variant="h5" as="h4" mb="3">
            {pretitle.value}
          </LinariaHeading>
          <LinariaHeading variant="h1" as="h2" mb="3">
            {title.value}
          </LinariaHeading>
          <LinariaText className={cn('mx-auto max-w-[30rem]', 'lg:mx-0 lg:max-w-none')}>
            {description.value}
          </LinariaText>
          <div
            className={cn(
              'mt-4 grid grid-cols-1 items-center gap-6 text-center',
              'sm:mt-8 sm:gap-8',
              'md:mt-12 md:gap-10',
              'lg:grid-cols-2 lg:gap-3',
            )}
          >
            {shop_button_url && shop_button_url.value !== '' && (
              <div className={cn('flex w-full justify-center', 'sm:w-auto', 'lg:justify-start')}>
                <Button
                  as="link"
                  href={shop_button_url.value}
                  className={cn('min-w-full', 'md:min-w-[auto]')}
                  variant="dark"
                >
                  {t('shopGassan121')}
                </Button>
              </div>
            )}
            {more_info_button_url && more_info_button_url.value !== '' && (
              <div>
                <LinariaLink href={more_info_button_url.value}>
                  {t('moreAboutDiamonds')}
                </LinariaLink>
              </div>
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HomepageAboutDiamonds
