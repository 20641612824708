// @ts-strict-ignore
// The order of modular_content is not always exactly the same as the order in the UI
// Only the order of the value property is, but that's an HTML string
// It's currently a feature request at Kentico, but untill then we have to manually parse the html
// to get the data-codename attributes in the right order
// https://stackoverflow.com/questions/30420491/javascript-regex-to-get-tag-attribute-value
const regex = new RegExp(
  '[\\s\\r\\t\\n]*([a-z0-9\\-_]+)[\\s\\r\\t\\n]*=[\\s\\r\\t\\n]*([\'"])((?:\\\\\\2|(?!\\2).)*)\\2',
  'ig',
)
export function getDataCodenameValues(html: string): string[] {
  const items = html.split('</object>').map((str) => `${str}</object>`)
  let keys: string[] = []
  items.forEach((item, index) => {
    let match = regex.exec(item)
    let attributes = {}
    while ((match = regex.exec(item))) {
      attributes[match[1]] = match[3]
    }

    if (attributes['data-codename']) {
      keys.push(attributes['data-codename'])
    }
  })

  return keys
}
