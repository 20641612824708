import Item from './Item'
import { Grid } from '@components/gassan-ui'
import { CbSingleTextImage } from '@lib/kontent/models/CbSingleTextImage'
import { rem } from 'polished'
import { FC } from 'react'

type Props = {
  items: CbSingleTextImage[]
}

const Double: FC<Props> = ({ items }) => {
  return (
    <Grid
      gridTemplateColumns={{ _: '1fr', large: '1fr 1fr ' }}
      gridColumnGap={{ large: '4rem', xlarge: '6.5rem' }}
      gridRowGap={{ _: '1rem', small: '1.5rem', large: '2rem' }}
      m="0 auto"
      width={{ _: '100%', small: 'calc(100% - 8rem)', large: 'calc(100% - 5.5rem)' }}
      maxWidth={{ large: rem(1240) }}
      mb={{ large: '5rem' }}
    >
      {items.map((item, index) => (
        <Item
          key={index}
          title={item.title.value}
          description={item.description.value}
          image={item.image}
          linkHref={item.link_url.value}
          linkText={item.link_text.value}
          variant="double"
        />
      ))}
    </Grid>
  )
}

export default Double
