import CountrySelect from '@components/CountrySelect'
import { Field } from '@components/gassan-ui'
import { NumberElement } from '@lib/kontent/models/Elements'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type InputFieldsProps = {
  fields: string[]
  maxPersons: NumberElement
}

export const InputFields: FC<InputFieldsProps> = ({ fields, maxPersons }) => {
  const { t } = useTranslation('forms')
  return (
    <>
      <Field name="firstName" label={t('labels.firstName')} field="input" gridColumn="span 12" />
      <Field name="lastName" label={t('labels.lastName')} field="input" gridColumn="span 12" />
      <Field name="email" label={t('labels.email')} field="input" gridColumn="span 12" />
      {fields.includes('home_address') && (
        <>
          <Field name="street" label={t('labels.street')} field="input" gridColumn="span 5" />
          <Field
            field="input"
            name="houseNumber"
            label={t('labels.houseNumber')}
            gridColumn={{ _: 'span 7', large: 'span 4' }}
          />
          <Field
            field="input"
            name="houseNumberSuffix"
            label={t('labels.houseNumberSuffix')}
            gridColumn={{ _: 'span 5', large: 'span 3' }}
          />
          <Field
            field="input"
            name="postalCode"
            label={t('labels.postalCode')}
            gridColumn={{ _: 'span 12', large: 'span 5' }}
          />
          <Field
            name="city"
            label={t('labels.city')}
            field="input"
            gridColumn={{ _: 'span 12', large: 'span 7' }}
          />
          <CountrySelect name="country" label={t('labels.country')} />
        </>
      )}
      {fields.includes('phone_number') && (
        <Field name="phoneNumber" label={t('labels.phone')} field="input" gridColumn="span 12" />
      )}
      {fields.includes('note') && (
        <Field
          name="note"
          label={t('labels.messageAppointment')}
          field="textarea"
          gridColumn="span 12"
        />
      )}
      {fields.includes('dietary_requirements') && (
        <Field
          name="dietaryRequirements"
          label={t('labels.dietaryRequirements')}
          field="textarea"
          gridColumn="span 12"
        />
      )}
      {fields.includes('number_of_persons') && typeof maxPersons.value === 'number' && (
        <Field
          name="numberOfPersons"
          label={t('labels.numberOfPersons')}
          field="select"
          placeholder=" "
          gridColumn="span 12"
        >
          <option value="" defaultChecked disabled>
            {t('labels.numberOfPersons')}
          </option>
          {[...Array.from(new Array(maxPersons.value))].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </Field>
      )}
    </>
  )
}
