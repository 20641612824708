import { LinkImage } from '@components/LinkImage'
import { Container } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import { CbTwoLinkImages } from '@lib/kontent/models/CbTwoLinkImages'
import { FC } from 'react'

type Props = CbTwoLinkImages

const TwoLinkImages: FC<Props> = ({
  first_image,
  first_link_text,
  first_link_url,
  second_image,
  second_link_text,
  second_link_url,
}) => {
  return (
    <Container mb={{ _: '2rem', small: '4rem', large: '5rem' }}>
      <div className={cn('grid grid-cols-1 gap-5', 'md:grid-cols-2 md:gap-6')}>
        <LinkImage
          title={first_link_text.value}
          subtitle={first_link_text.value}
          href={first_link_url.value}
          imageSource={first_image.value[0].url}
        />
        <LinkImage
          title={second_link_text.value}
          subtitle={second_link_text.value}
          href={second_link_url.value}
          imageSource={second_image.value[0].url}
        />
      </div>
    </Container>
  )
}

export default TwoLinkImages
