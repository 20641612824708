// @ts-strict-ignore
import { Container, Flex, Heading, Span, Text } from '@components/gassan-ui'
import { CbQuote } from '@lib/kontent/models/CbQuote'
import { FC } from 'react'

type Props = CbQuote

const Quote: FC<Props> = ({ quote, author, variant }) => {
  const isRegular = variant.value[0].codename === 'regular'
  return (
    <Container
      mb={{ _: '2rem', small: '3rem', large: '5rem' }}
      maxWidth={isRegular && '57rem'}
      bg={isRegular ? 'pampas' : 'shades.50'}
      position="relative"
      p={
        isRegular
          ? { _: '1.5rem', small: '3rem', large: '4rem' }
          : { _: '1rem', small: '2rem 3.5rem ', large: '5rem 9.25rem ' }
      }
    >
      {isRegular ? (
        <Heading variant="h4" mb="0" color={'tobaccoBrown'}>
          {quote.value}
        </Heading>
      ) : (
        <Text
          fontFamily="quote"
          fontSize={{ _: '2rem', small: '4rem', large: '5rem' }}
          mb="0"
          textAlign="center"
        >
          {quote.value}
        </Text>
      )}
      {author && author.value.length > 0 && (
        <Flex
          opacity={isRegular && 0.5}
          alignItems="center"
          mt={{ _: '1rem', small: '1.5rem' }}
          justifyContent={isRegular ? 'flex-start' : 'center'}
        >
          <Span mr="0.5rem" color={isRegular ? 'tobaccoBrown' : 'shades.600'}>
            —
          </Span>
          <Text variant="small" mb="0" color={isRegular ? 'tobaccoBrown' : 'shades.600'}>
            {author.value}
          </Text>
        </Flex>
      )}
    </Container>
  )
}

export default Quote
