// @ts-strict-ignore
import { ProductCard } from '@components/ProductCard'
import { Article } from '@generated'
import { useWishlist } from '@lib/hooks/use-wishlist'
import { FC } from 'react'

type InspirationProductsProductCardProps = {
  data?: Article
}

export const InspirationProductsProductCard: FC<InspirationProductsProductCardProps> = ({
  data,
}) => {
  const wishlist = useWishlist()

  return data ? (
    <ProductCard
      href={`/p/${data.urlSlug}`}
      key={data.id}
      brand={data.brand}
      id={data.id}
      articleType={data.articleType}
      description={data.description}
      images={data.images}
      title={data.brand}
      subtitle={data.description}
      price={data.price}
      discountPrice={data.salePrice}
      hidePrice={!data.isPriceVisible}
      isOnWishlist={wishlist.articleIds.includes(data.id)}
      onClickWishlist={() =>
        wishlist.toggle({
          id: data.id,
          title: data.brand,
          subtitle: data.description,
          image: data.images[0],
        })
      }
      width="100%"
    />
  ) : (
    <ProductCard isLoading />
  )
}
