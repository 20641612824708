import { LinkImage } from '@components/LinkImage'
import { Container } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import { CbSingleImageFourVerticalLinkImages } from '@lib/kontent/models/CbSingleImageFourVerticalLinkImages'
import { FC } from 'react'

type Props = {
  items: CbSingleImageFourVerticalLinkImages[]
}

const FourVerticalLinkImages: FC<Props> = ({ items }) => {
  return (
    <Container mb={{ _: '2rem', small: '4rem', large: '5rem' }}>
      <div className={cn('grid grid-cols-1 gap-5', 'md:grid-cols-2', 'lg:grid-cols-4 lg:gap-6')}>
        {items.map((item) => (
          <LinkImage
            variant="vertical"
            title={item.text.value}
            href={item.url.value}
            imageSource={item.image.value[0].url}
          />
        ))}
      </div>
    </Container>
  )
}

export default FourVerticalLinkImages
