import { useEffect } from 'react'
import create from 'zustand'

const useStore = create<{ isReady: boolean; toggleReady: (isReady: boolean) => void }>((set) => ({
  isReady: false,
  toggleReady: (isReady: boolean) => set({ isReady }),
}))

export function useGigya() {
  const { isReady, toggleReady } = useStore()

  useEffect(() => {
    // Verify the script tag is somewhere in the dom
    const script = document.getElementById('gigya-script')
    if (!script) {
      console.warn(
        `You're using the useGigya hook but the Gigya script is not loaded. Please add the <Script /> component to your page.`,
      )
    }

    // If the callback from the Gigy script is called toggle to ready
    window.onGigyaServiceReady = function () {
      toggleReady(true)
    }

    // If gigya is already set (client side routing or HMR) toggle to ready
    if (window?.gigya) {
      toggleReady(true)
    }
  }, [toggleReady])

  return { isReady }
}
