import * as styles from './GigyaScreenSet.styles'
import { Spinner } from '@components/gassan-ui'
import { useGigya } from '@lib/cdc/use-gigya'
import { createLogger } from '@lib/create-logger'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

// In CDC there are different screens (startScreens) depending on the flow (screenSet)
type GigyaStartScreen =
  | 'gigya-register-screen-form-only'
  | 'gigya-forgot-password-screen'
  | 'gigya-reset-password-screen'
  | 'gigya-social-login-buttons'
  | 'gigya-complete-registration-screen'

type GigyaScreenSetGassanLiteRegistration = {
  screenSet: 'Gassan-LiteRegistration'
  startScreen?: never
}
type GigyaScreenSetGassanRegistrationLogin = {
  screenSet: 'Gassan-RegistrationLogin'
  startScreen?: GigyaStartScreen
}

type GigyaScreenSetProps = (
  | GigyaScreenSetGassanLiteRegistration
  | GigyaScreenSetGassanRegistrationLogin
) & {
  children?: never
}

const logger = createLogger('GigyaScreenSet')

export function GigyaScreenSet({ screenSet, startScreen }: GigyaScreenSetProps) {
  const { isReady } = useGigya()
  const { locale, reload } = useRouter()
  const latestLocale = useRef(locale)

  const id = `${screenSet}-${startScreen}`.toLocaleLowerCase()
  useEffect(() => {
    if (isReady) {
      logger.info(`showing screen set ${screenSet}`)
      window?.gigya?.accounts.showScreenSet({
        screenSet: screenSet,
        startScreen: startScreen,
        containerID: id,
      })
    }
  }, [isReady, screenSet, startScreen, id])

  useEffect(() => {
    // We're using next/script to load the gigya script in the right locale
    // but nextjs won't reload the script if the locale changes
    // to keep the advantages of next/script we reload the page if the locale changes and we're displaying a Gigya screenset
    if (isReady && latestLocale.current !== locale) {
      reload()
    }

    latestLocale.current = locale
  }, [locale, reload, latestLocale, isReady])

  return (
    <>
      <div
        className={styles.genericStyles}
        data-start-screen={startScreen}
        data-screen-set={screenSet}
      >
        <div id={id}>
          {!isReady && (
            <div>
              <Spinner size={24} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
