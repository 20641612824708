// @ts-strict-ignore
import { InputFields } from './InputFields'
import { getIsDateBlocked, getSchema } from './utils'
import DatepickerInput from '@components/Datepicker'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { useNotifications } from '@components/NotificationsProvider'
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import { Box, Button, Container, Field, Heading, Text } from '@gassan-ui'
import { useSubmitEventFormMutation } from '@generated'
import { capitalizeWords } from '@lib/capitalize-words'
import { cn } from '@lib/cn'
import { genericPushToTrackingLayer } from '@lib/data-layer-social-ads/generic-push-to-tracking-layer'
import { CbEventForm } from '@lib/kontent/models/CbEventForm'
import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import { FC } from 'react'

type Props = CbEventForm

const EventForm: FC<Props> = ({
  title,
  description,
  email_title,
  to_email_address,
  input_fields,
  date_field,
  configure_available_times,
  configure_maximum_number_of_people,
  call_to_actions,
  analytics_form_id,
  url_slug,
  custom_newsletter_checkbox_text,
}) => {
  const { t } = useTranslation(['notifications', 'forms', 'other', 'navigation'])
  const notifications = useNotifications()
  const [, submitEventForm] = useSubmitEventFormMutation()
  const inputFields =
    input_fields && input_fields.value ? input_fields.value.map((field) => field.codename) : []
  const ctas =
    call_to_actions && call_to_actions.value
      ? call_to_actions.value.map((field) => field.codename)
      : []
  const availableTimes = configure_available_times.value
    .replace(/ /g, '')
    .split(',')
    .filter((item) => item)
  const schema = getSchema(inputFields, ctas)
  const initialValues = {
    urlSlug: '',
    firstName: '',
    lastName: '',
    email: '',
    postalCode: '',
    city: '',
    street: '',
    country: 'NL',
    houseNumber: '',
    houseNumberSuffix: '',
    phoneNumber: '',
    note: '',
    dietaryRequirements: '',
    numberOfPersons: '',
    heardOfGassanVia: '',
    date: '',
    time: '',
    newsletterSignup: false,
    plazaBrilliantNews: false,
  }

  return (
    <Container mb={{ _: '2rem', small: '4rem', large: '5rem' }} maxWidth={rem(854)}>
      <Box mb={['1.5rem', '2rem']}>
        <Heading variant="h3" as="h3" textAlign={['center', 'left']} mb="0">
          {title.value}
        </Heading>
        {description.value && (
          <Text variant="regular" textAlign={['center', 'left']} mt={['1rem', '1rem']}>
            {description.value}
          </Text>
        )}
      </Box>

      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        onSubmit={async (values, { resetForm }) => {
          const { data, error } = await submitEventForm({
            input: {
              urlSlug: url_slug.value && url_slug.value,
              title: email_title.value,
              firstName: values.firstName ? capitalizeWords(values.firstName) : '',
              lastName: values.lastName ? capitalizeWords(values.lastName) : '',
              email: values.email ? values.email : '',
              city: values.city ? values.city : '',
              street: values.street ? values.street : '',
              postalCode: values.postalCode ? values.postalCode : '',
              country: values.country ? values.country : '',
              houseNumber: values.houseNumber ? values.houseNumber : '',
              houseNumberSuffix: values.houseNumberSuffix ? values.houseNumberSuffix : '',
              phoneNumber: values.phoneNumber ? values.phoneNumber : '',
              note: values.note ? values.note : '',
              toEmail: to_email_address.value,
              newsletter: values.newsletterSignup,
              plazaBrilliantNews: values.plazaBrilliantNews,
              dietaryRequirements: values.dietaryRequirements,
              numberOfPersons: values.numberOfPersons,
              heardOfGassanVia: values.heardOfGassanVia,
              preferredDate: values.date,
              preferredTime: values.time,
              referrer: window.location.pathname,
            },
          })
          if (error) {
            if (process.env.NODE_ENV === 'development') {
              console.error(error)
            }
            notifications.addNotification({
              variant: 'error',
              label: t('refreshAndTryAgain', { ns: 'notifications' }),
            })
          }

          if (data && data.submitEventForm) {
            notifications.addNotification({
              variant: 'confirmation',
              description: t('requestSent', { ns: 'notifications' }),
              label: t('weWillContactYou', { ns: 'notifications' }),
            })
            if (['preview', 'production'].includes(process.env.NEXT_PUBLIC_STAGE)) {
              genericPushToTrackingLayer('EventForm', 'Send')
            }
            resetForm()
          }
        }}
      >
        {({ isSubmitting, setFieldValue, values, setTouched }) => (
          <Form id={analytics_form_id.value}>
            <div className={cn('grid grid-cols-[repeat(12,1fr)] gap-x-4')}>
              {input_fields && input_fields.value && (
                <InputFields fields={inputFields} maxPersons={configure_maximum_number_of_people} />
              )}
              {input_fields &&
                input_fields.value &&
                inputFields.includes('date') &&
                date_field.value && (
                  <div className="col-span-12">
                    <DatepickerInput
                      isDateBlocked={getIsDateBlocked(date_field.value)}
                      name="preferredDate"
                      label={t('date', { ns: 'other' })}
                      value={values.date}
                      onChange={(date) => {
                        setFieldValue('date', date)
                      }}
                      onBlur={() => setTouched({ date: true })}
                    />
                  </div>
                )}
              {input_fields &&
                input_fields.value &&
                inputFields.includes('time') &&
                availableTimes.length > 0 && (
                  <Field
                    name="time"
                    label={t('labels.numberOfPersons', { ns: 'forms' })}
                    field="select"
                    placeholder=" "
                    gridColumn="span 12"
                  >
                    <option value="" defaultChecked disabled>
                      {t('labels.selectTime', { ns: 'forms' })}
                    </option>
                    {availableTimes.map((time, i) => (
                      <option key={i} value={time}>
                        {time}
                      </option>
                    ))}
                  </Field>
                )}
              {inputFields.includes('heard_of_gassan_via') && (
                <Field
                  name="heardOfGassanVia"
                  label={t('labels.heardOfGassanVia', { ns: 'forms' })}
                  field="input"
                  gridColumn="span 12"
                />
              )}
              <Field
                name="newsletterSignup"
                label={
                  <>
                    {custom_newsletter_checkbox_text && custom_newsletter_checkbox_text.value
                      ? custom_newsletter_checkbox_text.value
                      : t('labels.acceptNewsletterAndConditions', { ns: 'forms' })}
                    <LocalizedLink href={t('privacyPolicy', { ns: 'navigation' })}>
                      <a href={t('privacyPolicy', { ns: 'navigation' })}>
                        {t('moreInfo', { ns: 'other' })}
                      </a>
                    </LocalizedLink>{' '}
                  </>
                }
                field="checkbox"
                gridColumn="span 12"
              />
              {ctas.includes('create_account') && (
                <Box gridColumn="span 12" mt="1rem">
                  <LinariaText>
                    {t('wantToCreateAccount', { ns: 'other' })}
                    <LocalizedLink href={t('account.create.url', { ns: 'navigation' })} passHref>
                      <a>{t('here', { ns: 'other' })}</a>
                    </LocalizedLink>
                    .
                  </LinariaText>
                </Box>
              )}
              <Button variant="dark" type="submit" status={isSubmitting ? 'loading' : 'idle'}>
                {t('submits.sendMessage', { ns: 'forms' })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default EventForm
