// @ts-strict-ignore
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Box, Grid, Heading, Image, Text, theme } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import { getKontentImage } from '@lib/kontent'
import { ContentPage } from '@lib/kontent/models/ContentPage'
import { rem } from 'polished'
import { FC } from 'react'
import styled from 'styled-components'

const Anchor = styled.a`
  outline: 0;
  border: 0;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      h4 {
        text-decoration: underline;
      }
      picture {
        opacity: 0.8;
      }
    }
  }
`

type ContentPageGridProps = {
  items: ContentPage[]
}

const ContentPageGrid: FC<ContentPageGridProps> = ({ items }) => {
  const filteredItems = items.filter(
    (item) => item.grid_pre_title.value && item.grid_title.value && item.grid_image.value[0],
  )

  return (
    <Box
      as="section"
      m={{ _: '0 auto' }}
      width={{ _: 'calc(100% - 2rem)', medium: 'calc(100% - 4rem)', large: 'calc(100% - 5.5rem)' }}
      maxWidth={{ large: rem(1450) }}
      mb={{ _: '2rem', small: '4rem', large: '5rem' }}
    >
      <Grid
        gridTemplateColumns={{ _: '1fr', medium: '1fr 1fr', large: '1fr 1fr 1fr' }}
        gridRowGap={{ _: '2rem', medium: '4rem', large: '5rem' }}
        gridColumnGap={{ medium: '1.5rem', large: '2.25rem' }}
      >
        {filteredItems.map((item, key) => (
          <LocalizedLink href={`/${item.url_slug.value}`} key={key}>
            <Anchor title={item.title.value}>
              <Image
                className={cn('mb-4 aspect-[11/10]', 'md:mb-6')}
                sources={[
                  getKontentImage(item.grid_image.value[0].url, { width: 600 }),
                  getKontentImage(item.grid_image.value[0].url, { width: 900 }),
                ]}
                alt={item.grid_image.value[0].description}
              />
              <Text
                variant="small"
                color={theme.colors.shades[500]}
                mb={{ _: '0.25rem', medium: '0.5rem' }}
              >
                {item.grid_pre_title.value}
              </Text>
              <Heading variant="h4" as="h4" mb="0">
                {item.grid_title.value}
              </Heading>
            </Anchor>
          </LocalizedLink>
        ))}
      </Grid>
    </Box>
  )
}

export default ContentPageGrid
