// @ts-strict-ignore
import { Image } from '@gassan-ui'
import { cn } from '@lib/cn'
import { getKontentImage } from '@lib/kontent'
import { FC } from 'react'

type Props = {
  src: string
  alt: string
  previewSrc?: string
}

const FeaturedImage: FC<Props> = ({ src, alt, previewSrc }) => {
  const sources = (isPreview?: boolean) => {
    const url = isPreview ? previewSrc : src
    return [getKontentImage(url, { width: 1050 })]
  }

  return (
    <div
      className={cn(
        'mt-0 flex w-full max-w-none justify-center',
        'sm:mx-auto sm:mt-14',
        'md:mt-[4.5rem] md:landscape:max-h-[70vh] md:landscape:overflow-hidden',
        'lg:mt-0 lg:max-w-[44rem]',
      )}
    >
      <Image
        className={cn('aspect-[2/1]', 'md:aspect-[10/7]')}
        placeholderSrc={getKontentImage(src, { width: 50 })}
        sources={sources()}
        alt={alt}
      />
    </div>
  )
}

export default FeaturedImage
