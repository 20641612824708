import { Parent } from './elements'
import { CbRichContent } from '@lib/kontent/models/CbRichContent'
import { rem } from 'polished'
import { FC } from 'react'

type Props = CbRichContent

const RichContent: FC<Props> = ({ content }) => {
  return (
    <Parent
      mb={{ _: '2rem', small: '4rem', large: '5rem' }}
      maxWidth={rem(860)}
      dangerouslySetInnerHTML={{ __html: content.value }}
    />
  )
}

export default RichContent
