// @ts-strict-ignore
import AppointmentUSPBlock from '@components/AppointmentUSPBlock'
import {
  CbAuthor,
  CbAutoplayVideo,
  CbBrandSlider,
  CbBrands,
  CbCDCForm,
  CbCarouselBoxed,
  CbCarouselSquareWide,
  CbCbHomepageCraftsmanshipVideo,
  CbCenteredTextOnWideImageBackground,
  CbContactForm,
  CbContentBlockShopCategories,
  CbContentPageGrid,
  CbDemoAdminEventForm,
  CbDualImage,
  CbEmarsysHomeFeaturedProducts,
  CbEventForm,
  CbFourVerticalLinkImages,
  CbHeader,
  CbHomepageAboutDiamonds,
  CbHomepageBookATour,
  CbHomepageCtaBanner,
  CbHomepageMyGassanSection,
  CbHtmlEmbed,
  CbImageProduct,
  CbImageProductRow,
  CbInspirationProducts,
  CbIsolatedText,
  CbIsolatedTitle,
  CbMultipleTextImageBoxed,
  CbNewsletterForm,
  CbProductRow,
  CbQuote,
  CbRichContent,
  CbSingleImage,
  CbSpacer,
  CbStreamer,
  CbTable,
  CbTextImageBoxed,
  CbTextImageOnBgColor,
  CbTextInRectangleOnImageBackground,
  CbTwoColumnText,
  CbTwoLinkImages,
  CbVideo,
  FlexibleTextOnWideImageBackground,
} from '@components/_content-page'
import { Box } from '@components/gassan-ui'
import { FC, Fragment } from 'react'

const blockTypes = [
  'cb_text_image_boxed',
  'cb__large_text_image_boxed',
  'cb_dual_triple_images',
  'cb_dual_image_wide',
  'cb_text_and_image_on_background_color',
  'cb_text_in_rectangle_on_image_background',
  'cb_single_image',
  'cb_right_left_text_on_wide_image_background',
  'cb_centered_text_on_image_background',
  'cb_carousel_boxed',
  'cb_carousel_square_wide',
  'cb_autoplay_video',
  'cb_video',
  'cb_product_row',
  'cb_image_product',
  'cb_brand_slider',
  'cb_header',
  'cb_isolated_title',
  'cb_isolated_text',
  'cb_two_column_text',
  'cb_rich_content',
  'cb_author',
  'cb_quote',
  'cb_streamer',
  'cb_contactform',
  'cb_html_embed',
  'cb_table',
  'cb_carousel_square_wide',
  'cb_carousel_boxed',
  'cb_content_page_grid',
  'cb___event_form',
]

export type ContentBlocksProps = {
  contentBlocks: {
    type: (typeof blockTypes)[number]
    elements: any
  }[]
  modularContent: {
    [key: string]: any
  }
}

const shouldDisplaySpacer = (nextBlock: any, curVideoVariant?: string): boolean => {
  // Do not display a spacer between two "Combo" blocks
  // Do not display a spacer between two full width video blocks
  //
  // Do display a spacer if the next block is not a combo block
  // Do display a spacer if of two consecutive video blocks, at least one of theme is not full width
  const nextIsCombo = [
    'cb_video',
    'cb_autoplay_video',
    'cb_text_in_rectangle_on_image_background',
    'cb_text_and_image_on_background_color',
    'cb_centered_text_on_image_background',
    'cb_right_left_text_on_wide_image_background',
  ].includes(nextBlock.type)

  if (nextIsCombo && curVideoVariant) {
    if (nextBlock.elements.display_wide_boxed) {
      return [nextBlock.elements.display_wide_boxed.value[0].codename, curVideoVariant].includes(
        'boxed_variant',
      )
    }
    return false
  }
  return nextIsCombo ? false : true
}

const ContentBlocks: FC<ContentBlocksProps> = ({ contentBlocks, modularContent }) => {
  return (
    <>
      {contentBlocks.map((block, index) => {
        switch (block.type) {
          case 'cb_header':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbHeader {...block.elements} />
              </Fragment>
            )
          case 'cb_dual_triple_images':
            return (
              block.elements.items.modular_content && (
                <Fragment key={index}>
                  {index === 0 && <CbSpacer />}
                  <CbMultipleTextImageBoxed
                    items={block.elements.items.modular_content.map(
                      (key) => modularContent[key].elements,
                    )}
                  />
                </Fragment>
              )
            )
          case 'cb_text_image_boxed':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbTextImageBoxed
                  title={block.elements.title.value !== '' && block.elements.title.value}
                  description={block.elements.description.value}
                  image={block.elements.image}
                  tabletImage={block.elements.tablet_image}
                  order={
                    block.elements.order.value[0].codename === 'text_left___image_right'
                      ? 'text-image'
                      : 'image-text'
                  }
                  linkHref={block.elements.link_url.value}
                  linkText={block.elements.link_text.value}
                />
              </Fragment>
            )
          case 'cb__large_text_image_boxed':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbTextImageBoxed
                  description={block.elements.description.value}
                  image={block.elements.image}
                  tabletImage={block.elements.tablet_image}
                  order={
                    block.elements.order.value[0].codename === 'text_left___image_right'
                      ? 'text-image'
                      : 'image-text'
                  }
                  linkHref={block.elements.link_url.value}
                  linkText={block.elements.link_text.value}
                  variant="large"
                />
              </Fragment>
            )
          case 'cb_text_in_rectangle_on_image_background':
            return (
              <Fragment key={index}>
                <CbTextInRectangleOnImageBackground {...block.elements} />
                {contentBlocks[index + 1] && shouldDisplaySpacer(contentBlocks[index + 1]) && (
                  <Box height={{ large: '5rem' }} />
                )}
              </Fragment>
            )
          case 'cb_dual_image_wide':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbDualImage
                  items={block.elements.items.modular_content.map(
                    (key) => modularContent[key].elements,
                  )}
                />
              </Fragment>
            )
          case 'cb_single_image':
            return (
              <Fragment key={index}>
                {index === 0 &&
                  block.elements.wide_variant.value[0]?.codename !== 'display_wide_variant' && (
                    <CbSpacer />
                  )}
                <CbSingleImage {...block.elements} />
                {contentBlocks[index + 1] &&
                  block.elements.wide_variant.value[0] === 'wide' &&
                  shouldDisplaySpacer(contentBlocks[index + 1]) && (
                    <Box height={{ _: '2rem', small: '4rem', large: '5rem' }} />
                  )}
              </Fragment>
            )
          case 'cb_video':
            return (
              <Fragment key={index}>
                {
                  // apply spacer if the element is the first element and the video variant === boxed_variant
                  index === 0 &&
                    block.elements.display_wide_boxed.value[0].codename === 'boxed_variant' && (
                      <CbSpacer />
                    )
                }
                <CbVideo {...block.elements} />
                {contentBlocks[index + 1] &&
                  shouldDisplaySpacer(
                    contentBlocks[index + 1],
                    block.elements.display_wide_boxed.value[0].codename,
                  ) && <Box height={{ _: '2rem', small: '4rem', large: '5rem' }} />}
              </Fragment>
            )
          case 'cb_autoplay_video':
            return (
              <Fragment key={index}>
                {
                  // apply spacer if the element is the first element and the video variant === boxed_variant
                  index === 0 &&
                    block.elements.display_wide_boxed.value[0].codename === 'boxed_variant' && (
                      <CbSpacer />
                    )
                }
                <CbAutoplayVideo {...block.elements} />
                {contentBlocks[index + 1] &&
                  shouldDisplaySpacer(
                    contentBlocks[index + 1],
                    block.elements.display_wide_boxed.value[0].codename,
                  ) && <Box height={{ _: '2rem', small: '4rem', large: '5rem' }} />}
              </Fragment>
            )
          case 'cb_text_and_image_on_background_color':
            return (
              <Fragment key={index}>
                <CbTextImageOnBgColor {...block.elements} />
                {contentBlocks[index + 1] && shouldDisplaySpacer(contentBlocks[index + 1]) && (
                  <Box height={{ _: '2rem', small: '4rem', large: '5rem' }} />
                )}
              </Fragment>
            )
          case 'cb_centered_text_on_image_background':
            return (
              <Fragment key={index}>
                <CbCenteredTextOnWideImageBackground {...block.elements} />
                {contentBlocks[index + 1] && shouldDisplaySpacer(contentBlocks[index + 1]) && (
                  <Box height={{ _: '2rem', small: '4rem', large: '5rem' }} />
                )}
              </Fragment>
            )
          case 'cb_right_left_text_on_wide_image_background':
            return (
              <Fragment key={index}>
                <FlexibleTextOnWideImageBackground {...block.elements} />
                {contentBlocks[index + 1] && shouldDisplaySpacer(contentBlocks[index + 1]) && (
                  <Box height={{ large: '5rem' }} />
                )}
              </Fragment>
            )
          case 'cb_product_row':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbProductRow {...block.elements} />
              </Fragment>
            )
          case 'cb_image_product':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbImageProduct {...block.elements} />
              </Fragment>
            )
          case 'cb_brand_slider':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbBrandSlider
                  items={block.elements.brands.value.map((key) => modularContent[key].elements)}
                />
              </Fragment>
            )
          case 'cb_isolated_text':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbIsolatedText {...block.elements} />
              </Fragment>
            )
          case 'cb_isolated_title':
            return <CbIsolatedTitle key={index} {...block.elements} />
          case 'cb_two_column_text':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbTwoColumnText {...block.elements} />
              </Fragment>
            )
          case 'cb_quote':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbQuote {...block.elements} />
              </Fragment>
            )
          case 'cb_author':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbAuthor {...modularContent[block.elements.author.modular_content[0]].elements} />
              </Fragment>
            )
          case 'cb_contactform':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbContactForm {...block.elements} />
              </Fragment>
            )
          case 'cb_rich_content':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbRichContent {...block.elements} />
              </Fragment>
            )
          case 'cb_table':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbTable {...block.elements} />
              </Fragment>
            )
          case 'cb_streamer':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbStreamer {...block.elements} />
              </Fragment>
            )
          case 'cb_html_embed':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbHtmlEmbed {...block.elements} />
              </Fragment>
            )
          case 'cb_carousel_square_wide':
            return block.elements.images_drag_drop.value.length < 5 ? null : (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbCarouselSquareWide
                  {...block.elements}
                  images={block.elements.images.modular_content.map(
                    (key) => modularContent[key].elements,
                  )}
                />
              </Fragment>
            )
          case 'cb_carousel_boxed':
            return block.elements.images_drag_drop.value.length < 7 ? null : (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbCarouselBoxed
                  {...block.elements}
                  images={block.elements.images.modular_content.map(
                    (key) => modularContent[key].elements,
                  )}
                />
              </Fragment>
            )
          case 'cb_content_page_grid':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbContentPageGrid
                  items={block.elements.items.value.map((key) => modularContent[key].elements)}
                />
              </Fragment>
            )
          case 'cb___event_form':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbEventForm {...block.elements} />
              </Fragment>
            )
          case 'cb___image___product_row':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbImageProductRow {...block.elements} />
              </Fragment>
            )
          case 'cb___two_link_images':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbTwoLinkImages {...block.elements} />
              </Fragment>
            )
          case 'cb___four_vertical_link_images':
            return block.elements.items.modular_content.length < 4 ? null : (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbFourVerticalLinkImages
                  items={block.elements.items.modular_content.map(
                    (key) => modularContent[key].elements,
                  )}
                />
              </Fragment>
            )
          case 'cb___inspiration___products':
            return block.elements.items.modular_content.length >= 4 ? (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbInspirationProducts
                  {...block.elements}
                  items={block.elements.items.modular_content.map(
                    (key) => modularContent[key].elements,
                  )}
                />
              </Fragment>
            ) : null
          case 'cb___brands': {
            return block.elements.items.modular_content.length < 6 ? null : (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbBrands
                  {...block.elements}
                  items={block.elements.items.modular_content.map(
                    (key) => modularContent[key].elements,
                  )}
                />
              </Fragment>
            )
          }
          case 'homepage_cb___categories': {
            return block.elements.categories.value.length !== 6 ? null : (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbContentBlockShopCategories
                  {...block.elements}
                  items={block.elements.categories.value.map((key) => modularContent[key].elements)}
                />
              </Fragment>
            )
          }
          case 'homepage_cb___craftsmanship_video': {
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbCbHomepageCraftsmanshipVideo {...block.elements} />
              </Fragment>
            )
          }
          case 'homepage_cb___emarsys_products_row': {
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbEmarsysHomeFeaturedProducts />
              </Fragment>
            )
          }
          case 'homepage_cb___about_diamonds': {
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbHomepageAboutDiamonds {...block.elements} />
              </Fragment>
            )
          }
          case 'homepage_cb___book_a_tour': {
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbHomepageBookATour {...block.elements} />
              </Fragment>
            )
          }
          case 'homepage_cb___mygassan_membership': {
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbHomepageMyGassanSection {...block.elements} />
              </Fragment>
            )
          }
          case 'homepage_cb___cta_banner': {
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbHomepageCtaBanner {...block.elements} />
              </Fragment>
            )
          }
          case 'cb___opt_in___opt_out_newsletters':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbNewsletterForm {...block.elements} />
              </Fragment>
            )
          case 'cb___admin_event_form':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbDemoAdminEventForm {...block.elements} />
              </Fragment>
            )
          case 'cb___contact_us___modal':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <AppointmentUSPBlock
                  title={block.elements.title?.value ?? null}
                  text={block.elements.description?.value ?? null}
                  buttonText={block.elements.button_text?.value ?? null}
                  customImage={block.elements.featured_image.value[0] ?? null}
                />
              </Fragment>
            )
          case 'cb___cdc_form':
            return (
              <Fragment key={index}>
                {index === 0 && <CbSpacer />}
                <CbCDCForm {...block.elements} />
              </Fragment>
            )
          default:
            return null
        }
      })}
    </>
  )
}

export default ContentBlocks
