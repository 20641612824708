import { parse } from '@config/theme';
export const label = parse({
  display: 'grid',
  alignItems: 'flex-start',
  width: '100%'
}, "l4di58t");
export const item = parse({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  pb: '4',
  mb: '4'
}, "i1v9d1um");

require("./Fields.styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Fields.styles.ts");