import LocalizedLink from '@components/Localization/LocalizedLink'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { linkStyle } from '@components/gassan-ui/Typography/linariaStyles'
import { Container, Image } from '@gassan-ui'
import { cn } from '@lib/cn'
import { getKontentImage } from '@lib/kontent'
import { CategoryPage } from '@lib/kontent/models/CategoryPage'
import { HomepageCbCategories } from '@lib/kontent/models/HomepageCbCategories'
import { cx } from '@linaria/core'
import { FC } from 'react'

type Props = HomepageCbCategories & {
  items: CategoryPage[]
}

const ContentBlockShopCategories: FC<Props> = ({ title, items }) => {
  return (
    <Container>
      <LinariaHeading variant="h2" as="h2" mb={{ _: '6', medium: '8', xlarge: '10' }}>
        {title.value}
      </LinariaHeading>
      <div
        className={cn(
          'grid grid-cols-2 gap-x-4 gap-y-6',
          'sm:grid-cols-3 sm:gap-y-8',
          'lg:grid-cols-6 lg:gap-y-6',
          'xxl:gap-x-6',
        )}
      >
        {items.map((item, index) => (
          <LocalizedLink href={`/${item.url_slug.value}`} key={index}>
            <a className="group relative overflow-hidden" title={item.title.value}>
              <div className="bg-shade-100">
                <Image
                  placeholderSrc={getKontentImage(item.image.value[0]?.url, { width: 40 })}
                  sources={[getKontentImage(item.image.value[0]?.url, { width: 320 })]}
                  alt="Alt text"
                  className={cn('aspect-[5/6]', 'sm:aspect-[5/7]')}
                  loading="lazy"
                />
              </div>
              <div
                className={cn(
                  'duration-400 relative bg-shade-100 py-2 transition-[padding] ease-in-out',
                  "before:duration-400 before:absolute before:bottom-0 before:left-0 before:z-0 before:block before:h-full before:w-full before:bg-white before:transition-all before:ease-in-out before:content-[''] group-hover:before:translate-y-full",
                  'sm:py-4',
                  'lg:py-6',
                )}
                data-content
              >
                <span
                  className={cx(
                    'relative max-w-full transition-all delay-100 duration-100 ease-in-out',
                    'duration-250 inline-block w-auto items-center truncate border-b border-current p-0 pb-1 leading-6 text-bigStone outline-none',
                    'opacity-100 transition-opacity duration-100 ease-in-out group-hover:opacity-0',
                    linkStyle,
                  )}
                  data-text
                >
                  {item.title.value}
                </span>
                <span
                  className={cx(
                    'absolute left-6 max-w-[calc(100%-1.5rem)] translate-y-2 opacity-0 transition-all duration-100 ease-in-out',
                    'duration-250 inline-block w-auto items-center truncate border-b border-current p-0 pb-1 leading-6 text-bigStone outline-none',
                    'translate-y-full transform delay-100 group-hover:translate-y-0 group-hover:opacity-100',
                    linkStyle,
                  )}
                  data-hover-text
                >
                  {item.title.value}
                </span>
              </div>
            </a>
          </LocalizedLink>
        ))}
      </div>
    </Container>
  )
}

export default ContentBlockShopCategories
