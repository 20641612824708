import RichContentField from './RichContentField'
import { OptinOptoutForm } from '@components/optin-optout-form'
import { Box, Container, Heading } from '@gassan-ui'
import { CbOptInOptOutNewsletters } from '@lib/kontent/models/CbOptInOptOutNewsletters'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

type Props = CbOptInOptOutNewsletters

const NewsletterForm: FC<Props> = ({ title, description }) => {
  const [email, setEmail] = useState<string | null>(null)
  const { query } = useRouter()

  useEffect(() => {
    if (query && query.email) {
      setEmail(query.email as string)
    }
  }, [query])

  return (
    <Container mb={{ _: '2rem', small: '4rem', large: '5rem' }}>
      <Box mb={['1.5rem', '2rem']} maxWidth="650px">
        <Heading variant="h2" as="h1" textAlign={['center', 'left']} mb="4">
          {title.value}
        </Heading>
        {description.value && <RichContentField {...description} size="regular" />}
      </Box>

      <OptinOptoutForm email={email} />
    </Container>
  )
}

export default NewsletterForm
